import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <header id="hello-there-im-full">
      <h1><a href="http://www.robertreale.com/">robert reale : composer</a></h1>
      <h2>robert reale : composer</h2>
    </header>
      
    <nav>
      <div id="nav-inside">
        <h1>robert reale <span>: composer</span></h1>
        
        <div id="nav-menu">
    
          <div id="nav-share">
            <span></span>
          
          <div id="share-wrapper" className="clearfix"> 
      <div id="share-body"> 
        <ul> 
          <li><a className="share-linkedin external" href="http://www.linkedin.com/shareArticle?mini=true&amp;url=http%3A%2F%2Fwww.robertreale.com%2F&amp;title=robert%20reale%20:%20composer" target="_blank">LinkedIn</a></li> 
          <li><a className="share-facebook external" href="http://www.facebook.com/sharer.php?u=http%3A%2F%2Fwww.robertreale.com%2F&amp;t=robert%20reale%20:%20composer" target="_blank">Facebook</a></li> 
          <li><a className="share-delicious external" href="http://del.icio.us/post?v=4&amp;amp;noui&amp;amp;jump=close&amp;amp;url=http%3A%2F%2Fwww.robertreale.com%2F&amp;title=robert%20reale%20:%20composer" target="_blank">delicious</a></li> 
          <li><a className="share-twitter external" href="http://twitter.com/home?status=robert%20reale%20:%20composer%20-%20http%3A%2F%2Fwww.robertreale.com%2F" target="_blank">Twitter</a></li> 
          <li><a className="share-stumbleupon external" href="http://www.stumbleupon.com/submit?url=http%3A%2F%2Fwww.robertreale.com%2F&amp;title=robert%20reale%20:%20composer" target="_blank">StumbleUpon</a></li> 
        </ul> 
      </div>
    </div>
          
          </div>
        
          {/*<!--<a href="http://www.robertreale.com/mainmenu-news/" className="section-hidden aside-scroller">news</a> -->
          <a href="http://www.robertreale.com/mainmenu-press/" className="section-hidden aside-scroller">press</a>
          <!--<a href="#tweetblender-4" className="section-hidden aside-scroller">tweets</a>-->
          <!--<a href="http://www.robertreale.com/mainmenu-blog/" className="section-hidden aside-scroller">blog</a>-->
          <a href="http://www.robertreale.com/mainmenu-bio/" className="section-hidden aside-scroller">bio</a>
          <a href="http://www.robertreale.com/mainmenu-links/" className="section-hidden aside-scroller">links</a>
          <a href="http://www.robertreale.com/mainmenu-contact/" className="section-hidden aside-scroller">contact</a> */}
        </div>  
      </div>
    </nav>
    
      <div className="backgrounds">
        <img src="../06.jpg"/>
        {/*<img src="http://www.robertreale.com/wp-content/themes/sidewinder-schramindustries%20full/images/08.jpg"/>  
        <img src="http://www.robertreale.com/wp-content/themes/sidewinder-schramindustries%20full/images/10.jpg"/>
        <img src="http://www.robertreale.com/wp-content/themes/sidewinder-schramindustries%20full/images/11.jpg"/>
        <img src="http://www.robertreale.com/wp-content/themes/sidewinder-schramindustries%20full/images/14.jpg"/>
        <img src="http://www.robertreale.com/wp-content/themes/sidewinder-schramindustries%20full/images/15.jpg"/>
        <img src="http://www.robertreale.com/wp-content/themes/sidewinder-schramindustries%20full/images/16.jpg"/>
        <img src="http://www.robertreale.com/wp-content/themes/sidewinder-schramindustries%20full/images/17.jpg"/>*/}
      </div>
  
    <section>
      <div id="contents">
        <article id="post-2">
          
          <h1><a href="http://www.robertreale.com/" rel="bookmark" title="Permanent Link to Bio">Bio</a></h1>
            <p>Shortly after graduating music school and several years touring in jazz and rock bands, Rob moved to New York in the mid-80’s where he responded to a demand for authentic, hi-end music for film and television and founded the production music library, 8118 Music.  He gigged in clubs and pits off-Broadway  and also began collaborating with his brother Willie creating musical theater.  They are a winning combination:  Rob provides the music, Willie, the words.  They had several Off-Broadway successes before their production, <em>A Year With Frog and Toad</em> brought them to Broadway and garnered Rob a Tony nomination for Best Original Score Music.   While continuing to bring musical stories to the stage, he founded 4 Elements Music with a roster of fellow composers which allowed him to expand on the work he started with 8118 Music.</p>
<p>Since its inception, 4 Elements Music has grown tremendously as Rob and his team work extensively in providing music for major network news, dramatic series and documentaries, film, and commercials.  The 4EM library now includes over 5,000 cuts by a roster of over 40 accomplished composers, and regularly provides music for ABC&#8217;s <em>Good Morning America</em>, <em>20/20</em>,  and <em>PrimeTime</em>.  Other regular clients include NBC, CBS, Showtime and many others.  4 Elements Music has now recently expanded worldwide, having signed deals with sub publishers and distributors throughout Europe, Asia and Australia.</p>
<p>In addition to scoring for many demanding television and film projects, Rob continues to work on several musical theatre productions, including <em>Johnny Baseball</em>, which will be running August 2012 at the Village Theatre Festival Of New Musicals (Seattle) after a highly successful run at the A.R.T.  (Cambridge) in 2010.  <em>Johnny Baseball</em> producers are currently planning the show’s route to New York.  Outside of work, Rob is passionate about cooking, perfecting his tennis game, and spending time with the production of which he’s most proud, his son Gabe.</p>
        </article>
      </div>
    </section>
    
    
    <aside>
      <ul>
      {/*<li id="text-4" className="widget widget_text"><h2 className="widgettitle">Press</h2>      <div className="textwidget"><a id="press_show_more" href="http://www.robertreale.com/press">more...</a></div></li>*/}
      <li id="text-3" className="widget widget_text"><h2 className="widgettitle">Bio</h2>      <div className="textwidget"><p>Shortly after graduating music school and several years touring in jazz and rock bands, Rob moved to New York in the mid-80’s where he responded to a demand for authentic, hi-end music for film and television and founded the production music library, 8118 Music.  He gigged in clubs and pits off-Broadway  and also began collaborating with his brother Willie creating musical theater.  They are a winning combination:  Rob provides the music, Willie, the words.  They had several Off-Broadway successes before their production, A Year With Frog and Toad brought them to Broadway and garnered Rob a Tony nomination for Best Original Score Music. </p>
</div>
    </li><li id="linkcat-2" className="widget widget_links"><h2 className="widgettitle">Links</h2>
  <ul className='xoxo blogroll'>
<li><a href="http://www.4elementsmusic.com" target="_blank">4 Elements Music</a></li>
<li><a href="http://www.8118music.com" target="_blank">8118 Music</a></li>
<li><a href="http://en.wikipedia.org/wiki/A_Year_with_Frog_and_Toad" target="_blank">A Year with Frog and Toad (wikipedia)</a></li>
<li><a href="http://www.imdb.com/name/nm0714047/" target="_blank">IMDB</a></li>
<li><a href="http://en.wikipedia.org/wiki/Robert_Reale" target="_blank">Wikipedia</a></li>

  </ul>
</li>
{/*<li id="cforms-2" className="widget widgetcform"><h2 className="widgettitle">Contact</h2>
    <div id="usermessagea" className="cf_info "></div>
    <form enctype="multipart/form-data" action="/#usermessagea" method="post" className="cform" id="cformsform">
    <ol className="cf-ol">
      <li id="li--1" className=""><label for="cf_field_1"><span>Name </span></label><input type="text" name="cf_field_1" id="cf_field_1" className="single" value=""/></li>
      <li id="li--2" className=""><label for="cf_field_2"><span>Email *required</span></label><input type="text" name="cf_field_2" id="cf_field_2" className="single fldemail fldrequired" value=""/><span className="emailreqtxt">(valid email required)</span></li>
      <li id="li--3" className=""><label for="cf_field_3"><span>Message *required</span></label><textarea cols="30" rows="8" name="cf_field_3" id="cf_field_3" className="area fldrequired"></textarea><span className="reqtxt">(required)</span></li>
    </ol>
    <fieldset className="cf_hidden">
      <legend>&nbsp;</legend>
      <input type="hidden" name="cf_working" id="cf_working" value="One%20moment%20please..."/>
      <input type="hidden" name="cf_failure" id="cf_failure" value="Please%20fill%20in%20all%20the%20required%20fields."/>
      <input type="hidden" name="cf_codeerr" id="cf_codeerr" value="Please%20double-check%20your%20verification%20code."/>
      <input type="hidden" name="cf_customerr" id="cf_customerr" value="yyy"/>
      <input type="hidden" name="cf_popup" id="cf_popup" value="nn"/>
    </fieldset>
    <p className="cf-sb"><input type="submit" name="sendbutton" id="sendbutton" className="sendbutton" value="Send Message"/></p></form><p className="linklove" id="ll"><a href="http://www.deliciousdays.com/cforms-plugin"><em>cforms</em> contact form by delicious:days</a></p>

   </li>*/}      </ul>
    </aside>
    <footer>
      <p>&copy; 2019 <a href="http://www.robertreale.com/" title="robert reale : composer">robert reale : composer</a> &bull; <a href="http://monzilla.biz/" title="WordPress Theme Design">H5 Theme</a> &bull; <a href="http://wordpress.org/" title="Powered by WordPress, state-of-the-art semantic personal publishing platform">WordPress 4.6.14</a> &bull; <a href="http://www.robertreale.com/feed/" title="Subscribe to Posts Feed">Entries (RSS)</a> &bull; <a href="http://www.robertreale.com/comments/feed/" title="Subscribe to Comments Feed">Comments (RSS)</a></p>
    </footer>
    <script async type='text/javascript' src='http://www.robertreale.com/wp-content/plugins/cleantalk-spam-protect/inc/cleantalk_nocache.js?random=5.51'></script>

{/*<script type='text/javascript' src='http://www.robertreale.com/wp-content/plugins/tweet-blender/js/lib.js?ver=4.6.14'></script>
<script type='text/javascript' src='http://www.robertreale.com/wp-content/plugins/tweet-blender/js/main.js?ver=4.6.14'></script>
<script type='text/javascript' src='http://www.robertreale.com/wp-includes/js/wp-embed.min.js?ver=4.6.14'></script>

  <script async type='text/javascript' src='http://www.robertreale.com/wp-content/plugins/cleantalk-spam-protect/inc/cleantalk_nocache.js?random=5.51'></script>*/}
  </Layout>
)

export default IndexPage
